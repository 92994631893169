import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { AutenticacaoCoreService } from '../domain/primecap/core/autenticacao-core.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { RespostaHttp } from '../domain/primecap/model/resposta-http';

@Injectable()
export class AutenticacaoInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenService: TokenService,
    private autenticacaoCoreService: AutenticacaoCoreService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let autenticacaoReq = request;
    const token = this.tokenService.carregarToken();

    if (!autenticacaoReq.url.includes('autenticacao/login')) {
      autenticacaoReq = this.adicionarTokenAoHeader(request, token);
    }

    return next.handle(autenticacaoReq).pipe(
      catchError((erro: HttpErrorResponse) => {
        if (erro instanceof HttpErrorResponse && !autenticacaoReq.url.includes('autenticacao/login') && erro.status == 401) {
          return this.erro401(autenticacaoReq, next);
        }
        return throwError(erro);
      })
    );
  }

  private erro401(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.tokenService.carregarRefreshToken();

      if (refreshToken) {
        return this.autenticacaoCoreService.gerarNovoToken(refreshToken).pipe(
          take(1),
          switchMap((res) => {
            this.isRefreshing = false
            this.tokenService.armazenarToken(res.dados[0].accessToken);
            this.refreshTokenSubject.next(res.dados[0].accessToken);
            return next.handle(this.adicionarTokenAoHeader(request, res.dados[0].accessToken))
          }),
          catchError((httpError: HttpErrorResponse) => {
            let error: RespostaHttp<any> = httpError.error;
            let msgErro: string = error.mensagem ? error.mensagem : 'Sua sessão expirou. Faça login novamente.';
            this.isRefreshing = false;
            this.tokenService.encerrarSessao();
            return throwError(msgErro);
          })
        );
      }

      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.adicionarTokenAoHeader(request, token)))
      );
    }
  }

  private adicionarTokenAoHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}
