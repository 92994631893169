import { UsuarioLogado } from 'src/app/domain/primecap/model/usuario-logado';
import { PermissoesUsuarioEnum } from '../../../model/enum/permissoes-usuario.enum';

export interface MenuSectionInterface {
  sectionName?: string,
  menuItens: MenuItemInterface[]
}

export interface MenuItemInterface {
  routerLink?: string,
  icon?: string,
  label: string,
  subItens?: MenuItemInterface[],
  expanded?: boolean
}

export class MenuItem {
  static retornaMenu(usuarioLogado: UsuarioLogado): any {
    if (usuarioLogado?.permissoes.length > 1 || usuarioLogado?.permissoes?.toString() == PermissoesUsuarioEnum.ASSESSOR) {
      let ehAssessor = false
      usuarioLogado?.permissoes.forEach((role) => {
        if (role.toString() == PermissoesUsuarioEnum.ASSESSOR) {
          ehAssessor = true
        }
      })
      if (ehAssessor) {
        return this.assessor;
      }
    } else if (usuarioLogado?.permissoes?.toString() == PermissoesUsuarioEnum.INVESTIDOR) {
      return this.investidor;
    } else if (usuarioLogado?.permissoes?.toString() == PermissoesUsuarioEnum.ADMINISTRADOR) {
      return this.administrador;
    } else if (usuarioLogado?.permissoes?.toString() == PermissoesUsuarioEnum.ESCRITORIO) {
      return this.escritorio;
    }else{
      return this.anonimo;
    }
  }
  private static investidor: MenuSectionInterface[] = [
    {
      // sectionName: '',
      menuItens: [
        {
          routerLink: 'ofertas',
          icon: 'money',
          label: 'Ofertas'
        },
        {
          routerLink: `dashboard/investidor/`,
          icon: 'dashboard',
          label: 'Dashboard'
        },
        {
          routerLink: '/investimentos/',
          icon: 'line-chart',
          label: 'Investimentos'
        },
      ]
    }
  ]

  private static assessor: MenuSectionInterface[] = [
    {
      sectionName: 'Eu Assessor',
      menuItens:[
        {
          routerLink: 'ofertas',
          icon: 'money',
          label: 'Ofertas'
        },
        {
          routerLink: 'push',
          icon: 'increase-money',
          label: 'PUSH'
        },
        {
          routerLink: 'dashboard/assessor/rodadas-ativas',
          icon: 'captacoes',
          label: 'Captacoes'
        },
        {
          routerLink: 'investidores',
          icon: 'users',
          label: 'Investidores'
        },
      ]
    },
    {
      sectionName: 'Eu Investidor',
      menuItens: [
        {
          routerLink: 'dashboard/investidor/',
          icon: 'dashboard',
          label: 'Meu Dashboard'
        },
        {
          routerLink: 'investimentos',
          icon: 'line-chart',
          label: 'Investimentos'
        },
      ]
    }
  ]

  private static administrador: MenuSectionInterface[] = [
    {
      // sectionName: '',
      menuItens: [
        {
          routerLink: 'ofertas',
          icon: 'money',
          label: 'Ofertas'
        },
        {
          routerLink: 'rodada/list',
          icon: 'rodada',
          label: 'Rodadas'
        },
        {
          icon: 'users',
          label: 'Usuários',
          subItens: [
            {
              icon: 'user-shield',
              routerLink: 'administrador/list',
              label: 'Administradores'
            },
            {
              icon: 'user2',
              routerLink: 'assessor/list',
              label: 'Assessores'
            },
            {
              icon: 'user1',
              routerLink: 'investidor/list',
              label: 'Investidores'
            },
            {
              icon: 'plus-user',
              routerLink: 'investidor/pre-cadastro/list',
              label: 'Pré Cadastro',
            },
            {
              icon: 'shopping-bag',
              routerLink: 'escritorio/list',
              label: 'Escritórios',
            },
          ]
        },
        {
          icon: 'startup',
          label: 'Empresas',
          subItens: [
            {
              icon: 'startup-carteira',
              routerLink: 'corretora/list',
              label: 'Corretoras',
            },
            {
              icon: 'construcao',
              routerLink: 'construtora/list',
              label: 'Construtoras',
            },
            {
              icon: 'shopping-bag',
              routerLink: 'fundo-investimento/list',
              label: 'Fundos',
            },
            {
              icon: 'predio',
              routerLink: 'empreendimento/list',
              label: 'Empreendimentos',
            },
            {
              icon: 'rocket',
              routerLink: 'startup/list',
              label: 'Startups',
            }
          ]
        },
        {
          icon: 'caneta',
          label: 'Cadastros',
          subItens: [
            {
              routerLink: 'socio/list',
              icon: 'user1',
              label: 'Sócios',
            },
            {
              routerLink: 'instituicao-financeira/list',
              icon: 'bank',
              label: 'Bancos',
            },
            {
              routerLink: 'setor/list',
              icon: 'business-competition',
              label: 'Setores',
            },
            {
              routerLink: 'momento/list',
              icon: 'three-stars',
              label: 'Momentos',
            },
          ]
        },
        {
          icon: 'options',
          label: 'Configurações',
          subItens: [
            {
              routerLink: 'info-primecap',
              icon: 'alert',
              label: 'Sistema'
            },
          ]
        },
      ]
    }
  ]
  private static escritorio: MenuSectionInterface[] = [
    {
      // sectionName: '',
      menuItens: [

        {
          routerLink: 'ofertas',
          icon: 'money',
          label: 'Ofertas'
        },
        {
          routerLink: 'dashboard/escritorio/rodadas-ativas',
          icon: 'captacoes',
          label: 'Captacoes'
        },
        {
          routerLink: 'assessores',
          icon: 'user',
          label: 'Assessores'
        },
        {
          routerLink: 'investidores',
          icon: 'users',
          label: 'Investidores'
        },
      ]
    }
  ]
  private static anonimo : MenuSectionInterface[] = [
    {
      // sectionName: '',
      menuItens: [

        {
          routerLink: 'login',
          icon: 'users',
          label: 'Entrar'
        },
        {
          routerLink: 'cadastrar',
          icon: 'user',
          label: 'Cadastrar'
        },
        {
          routerLink: 'rodadas',
          icon: 'dollar',
          label: 'Investir'
        },
        {
          icon: 'arrow-down',
          label: 'Quero Captar',
          subItens: [
            {
              icon: 'rocket',
              routerLink: 'https://form.respondi.app/HjbfzvpZ?old_address=true',
              label: 'Startup',
            },
            {
              icon: 'predio',
              routerLink: 'https://form.respondi.app/ILZlFmg3?old_address=true',
              label: 'Real Estate',
            },
          ]
        },
      ]
    }
  ]
}

