import { LoaderService } from '../domain/primecap/presentation/layouts/loader/loader.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalReq = 0;

  constructor(
    private loaderService: LoaderService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalReq++;

    const interceptReq = request.headers.has('intercept');

    if (interceptReq) {
      this.loaderService.setCarregando(true);
      request = request.clone({headers: request.headers.delete('intercept')});
    } else {
      // this.loaderService.setCarregando(false);
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.totalReq--;

        if (this.totalReq === 0) {
          this.loaderService.setCarregando(false);
        }
      })
    );
  }
}
