import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonPrimecapComponent } from './button-primecap.component';
import { IconsPrimecapModule } from '../icons-primecap/icons-primecap.module';



@NgModule({
  declarations: [
    ButtonPrimecapComponent
  ],
  imports: [
    CommonModule,
    IconsPrimecapModule
  ],
  exports: [
    ButtonPrimecapComponent
  ]
})
export class ButtonPrimecapModule { }
