import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonPrimecapComponent } from './button-primecap/button-primecap.component';
import { TabPrimecapComponent } from './tab-primecap/tab-primecap.component';
import { TabPrimecapModule } from './tab-primecap/tab-primecap.module';
import { ItemComponent } from './tab-primecap/item/item.component';
import { HeaderComponent } from './tab-primecap/header/header.component';
import { ContentComponent } from './tab-primecap/content/content.component';
import { SetorPrimecapComponent } from './setor-primecap/setor-primecap.component';
import { IconsPrimecapModule } from './icons-primecap/icons-primecap.module';
import { IconsPrimecapComponent } from './icons-primecap/icons-primecap.component';
import { ProgressbarPrimecapModule } from './progressbar-primecap/progressbar-primecap.module';
import { ProgressbarPrimecapComponent } from './progressbar-primecap/progressbar-primecap.component';
import { CardPrimecapModule } from './card-primecap/card-primecap.module';
import { CardPrimecapComponent } from './card-primecap/card-primecap.component';
import { SetorPrimecapModule } from './setor-primecap/setor-primecap.module';
import { InputPrimecapModule } from './input-primecap/input-primecap.module';
import { InputPrimecapComponent } from './input-primecap/input-primecap.component';
import { TagModule } from './tag/tag.module';
import { TagComponent } from './tag/tag.component';
import { ButtonMenuModule } from './button-menu/button-menu.module';
import { ButtonMenuComponent } from './button-menu/button-menu.component';
import { ButtonMenuItemComponent } from './button-menu/item/item.component';
import { ProgressPageModule } from './progress-page/progress-page.module';
import { ProgressPageComponent } from './progress-page/progress-page.component';
import { ButtonPrimecapModule } from './button-primecap/button-primecap.module';
import { SelectPrimecapModule } from './select-primecap/select-primecap.module';
import { SelectPrimecapComponent } from './select-primecap/select-primecap.component';
import { ModalPrimecapModule } from './modal-primecap/modal-primecap.module';
import { ModalPrimecapComponent } from './modal-primecap/modal-primecap.component';
import { CardDetalhesComponent } from './card-detalhes/card-detalhes.component';
import { CardDetalhesModule } from './card-detalhes/card-detalhes.module';
import { DocumentPrimecapModule } from './document-primecap/document-primecap.module';
import { DocumentPrimecapComponent } from './document-primecap/document-primecap.component';
import { EquipePrimecapModule } from './equipe-primecap/equipe-primecap.module';
import { EquipePrimecapComponent } from './equipe-primecap/equipe-primecap.component';
import { CardGeometricoModule } from './card-geometrico/card-geometrico.module';
import { CardGeometricoComponent } from './card-geometrico/card-geometrico.component';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TabPrimecapModule,
    IconsPrimecapModule,
    ProgressbarPrimecapModule,
    SetorPrimecapModule,
    CardPrimecapModule,
    InputPrimecapModule,
    TagModule,
    ButtonMenuModule,
    ProgressPageModule,
    ButtonPrimecapModule,
    SelectPrimecapModule,
    ModalPrimecapModule,
    CardDetalhesModule,
    DocumentPrimecapModule,
    EquipePrimecapModule,
    CardGeometricoModule,
  ],
  exports: [
    ButtonPrimecapComponent,
    TabPrimecapComponent,
    ItemComponent,
    ContentComponent,
    HeaderComponent,
    SetorPrimecapComponent,
    IconsPrimecapComponent,
    ProgressbarPrimecapComponent,
    CardPrimecapComponent,
    InputPrimecapComponent,
    TagComponent,
    ButtonMenuComponent,
    ButtonMenuItemComponent,
    ProgressPageComponent,
    SelectPrimecapComponent,
    ModalPrimecapComponent,
    CardDetalhesComponent,
    DocumentPrimecapComponent,
    EquipePrimecapComponent,
    CardGeometricoComponent,
  ]
})
export class ComponentsModule { }
