import { Component } from '@angular/core';

@Component({
  selector: 'erro-404',
  templateUrl: '404.component.html',
  styleUrls: ['error.component.css']
})
export class P404Component {

  constructor() { }

}
