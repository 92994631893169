import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressPageComponent } from './progress-page.component';
import { MatStepperModule} from '@angular/material/stepper'
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { ButtonPrimecapModule } from '../button-primecap/button-primecap.module';
import { InputPrimecapModule } from '../input-primecap/input-primecap.module';
import { SelectPrimecapModule } from '../select-primecap/select-primecap.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsPrimecapModule } from '../icons-primecap/icons-primecap.module';
import { ModalPrimecapModule } from '../modal-primecap/modal-primecap.module';
import { ModalPushComponent } from './modal-push/modal-push.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxCurrencyDirective } from "ngx-currency";

@NgModule({
  declarations: [
    ProgressPageComponent,
    ModalPushComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatDividerModule,
    MatGridListModule,
    MatExpansionModule,
    MatCardModule,
    MatTabsModule,
    MatBadgeModule,
    MatStepperModule,
    ButtonPrimecapModule,
    InputPrimecapModule,
    SelectPrimecapModule,
    FormsModule,
    IconsPrimecapModule,
    ModalPrimecapModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxCurrencyDirective
  ],
  exports: [
    ProgressPageComponent
  ]
})
export class ProgressPageModule { }
