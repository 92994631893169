import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsPrimecapComponent } from './icons-primecap.component';
import { IconsPrimecapService } from './icons-primecap.service';



@NgModule({
  declarations: [
    IconsPrimecapComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IconsPrimecapComponent
  ],
  providers: [
    IconsPrimecapService
  ]
})
export class IconsPrimecapModule { }
