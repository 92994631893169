import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxCurrencyDirective } from "ngx-currency";
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { PipesModule } from 'src/app/pipes/pipes.module';
import { ComponentsModule } from 'src/app/domain/primecap/components/components.module';

import { TabelaCrudComponent } from './tabela-crud.component';
import { CelulaTabelaComponent } from './celula-tabela/celula-tabela.component';
import { ColunaAcoesCrudComponent } from './coluna-acoes-crud/coluna-acoes-crud.component';
import { ColunaStatusesComponent } from './coluna-statuses/coluna-statuses.component';
import { CustomPaginator } from './tabela-paginador.config';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MultiplosFiltrosComponent } from './multiplos-filtros/multiplos-filtros.component';
import { MatExpansionModule } from '@angular/material/expansion'

@NgModule({
  declarations: [
    TabelaCrudComponent,
    CelulaTabelaComponent,
    ColunaAcoesCrudComponent,
    ColunaStatusesComponent,
    MultiplosFiltrosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxCurrencyDirective,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatCheckboxModule,
    MatExpansionModule,
    PipesModule,
    ComponentsModule
  ],
  exports: [
    TabelaCrudComponent
  ],
  providers: [
    DatePipe,
    provideEnvironmentNgxMask(),
    { provide: MatPaginatorIntl, useValue: CustomPaginator()}
  ]
})
export class TabelaCrudModule { }
