<app-master>
  <div class="col-12">
    <div class="row mt-5 d-flex align-items-center">
      <div class="col-12 mt-5 text-center">
        <h1 class="mt-5">404</h1>
        <h3 class="mt-5 pt-5">Página não encontrada.</h3>
        <p class="text-muted">A página que você procura não existe ou foi removida.</p>
      </div>
    </div>
  </div>
</app-master>
