import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPrimecapComponent } from './modal-primecap.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    ModalPrimecapComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    ModalPrimecapComponent
  ]
})
export class ModalPrimecapModule { }
