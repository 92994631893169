import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
import { TipoUsuarioEnum } from 'src/app/domain/primecap/model/enum/tipo-usuario.enum';
import { take, switchMap } from 'rxjs/operators';
import { StatusCadastroUsuarioEnum } from './../../../model/enum/status-cadastro-usuario.enum';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioLogado } from 'src/app/domain/primecap/model/usuario-logado';
import { TokenService } from 'src/app/services/token.service';
import { MenuItem, MenuItemInterface, MenuSectionInterface } from './menu-item';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/shared/snack-bar.service';
import { ModalConfirmacaoService } from 'src/app/shared/modal-confirmacao/modal-confirmacao.service';
import { EMPTY } from 'rxjs';
import { MasterService } from './master.service';
import { AssessorDoInvestidor } from '../../../model/assessor-do-investidor';
import { InvestidorCoreService } from '../../../core/investidor-core.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterComponent implements OnInit{
  usuario: UsuarioLogado;
  nomeUsuario!: string;
  isLogado: boolean = false;
  exibirToggle: boolean;
  collapsed = false;
  contentMargin = 0;
  menuSectionItems: MenuSectionInterface[];
  isAutentication: boolean;
  styleInit: string;
  assessor?: AssessorDoInvestidor;
  tipoUsuarioEnum = TipoUsuarioEnum;
  toolbarClass = environment.ambiente;
  contentMarginTop: number = 4;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private masterService: MasterService,
    private snackBarService: SnackBarService,
    private investidorCoreService: InvestidorCoreService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    public dialog: MatDialog,
    public loaderService: LoaderService
  ){
    this.masterService.getMenu().subscribe(()=>{
      if(this.collapsed){
        this.contentMargin = 14.562
      }
      this.ngOnInit()
    })

  }

  ngOnInit(): void {
    this.contentMarginTop = 4;
    this.isAutentication = false;
    this.usuario = this.tokenService.carregarUsuarioLogado()
    this.menuSectionItems = MenuItem.retornaMenu(this.usuario)
    if(this.usuario?.isAutenticado){
      if (this.usuario?.tipoUsuario == TipoUsuarioEnum.INVESTIDOR) {
        this.buscarAssessor(this.usuario.uuidTipoUsuario);
      }
      this.nomeUsuario = this.usuario?.nome?.toUpperCase();
      this.isAutentication = true;
      this.contentMarginTop = 0;
    }else{
      this.contentMargin = 0;
      this.styleInit = 'margin-left: 0px'
    }
    if(this.isAutentication && !this.collapsed){
      this.styleInit = 'margin-left: 5rem;'
      this.contentMargin = 5;
    }
  }

  openCloseMenu(){
    this.collapsed = !this.collapsed
    if(!this.collapsed){
      this.contentMargin = 5
    }else{
      this.contentMargin = 14.562
    }
  }

  index(){
    this.router.navigate(['index']);
  }

  investir(){
    this.router.navigate(['rodadas']);
  }

  captarStartup(){
    window.open("https://form.respondi.app/HjbfzvpZ?old_address=true", "_blank");
  }

  captarRealState(){
    window.open("https://form.respondi.app/ILZlFmg3?old_address=true", "_blank");
  }

  logar(){
    this.masterService.setMenu();
    this.router.navigate(['login']);
  }

  criarConta(){
    this.router.navigate(['cadastrar']);
  }

  logout(){
    this.tokenService.encerrarSessao();
    this.masterService.setMenu();
    this.router.navigate([`/login`]);
  }

  redirecionarUsuario(){
    let statusCadastro = this.tokenService.carregarUsuarioLogado()?.statusCadastro;

    switch (statusCadastro) {
      case StatusCadastroUsuarioEnum.ATIVO:
        this.router.navigate([`perfil/${this.usuario.tipoUsuario}`])
        break;
      case StatusCadastroUsuarioEnum.INATIVO:
        this.snackBarService.exibirMensagem('Seu cadastro encontra-se inativo. Entre em contato com a Primecap!', true);
        break;
      case StatusCadastroUsuarioEnum.PENDENTE:
        this.abrirModalConclusaoCadastro();
        break;
    }
  }

  abrirModalConclusaoCadastro(): void {
    const result$ = this.modalConfirmacaoService.mostrarConfirmacao(
      'Concluir Cadastro',
      'Para visualizar as informações do perfil você precisa finalizar seu cadastro na primecap.',
      'Ok, finalizar cadastro',
      'Não, obrigado(a)!'
    )
    result$.asObservable().pipe(
      take(1),
      switchMap(async result => result ? this.router.navigateByUrl('/investidor/C') : EMPTY)
    ).subscribe();
  }

  exibirSubItens(item: MenuItemInterface, index: number): void {
    for (let subItem of this.menuSectionItems[index].menuItens) {
      if (item != subItem && subItem.expanded) {
        subItem.expanded = false;
      }
    }
    item.expanded = !item.expanded;
    this.collapsed = true;
    this.contentMargin = 14.562;
  }

  buscarAssessor(investidorUuid: string): void {
    if (investidorUuid) {
      this.investidorCoreService.getAssessorDoInvestidor(investidorUuid).pipe(take(1)).subscribe(
        res => {
          this.assessor = res.dados[0]
        }
      );
    }
  }
}
