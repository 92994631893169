import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { classButton } from './model/class-button';

@Component({
  selector: 'button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.css']
})
export class ButtonMenuComponent implements AfterContentInit,AfterContentChecked {
	width! : number;
  // @Input
  @Input() label?: string ;
  @Input() buttonClass?: classButton;

  //@ViewChild
  @ViewChild(TemplateRef)   public contentTemplate!: TemplateRef<any> ;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterContentInit(): void {
  	if (!this.buttonClass) {
  		this.buttonClass = 'primario-button';
  	}
  }

  ngAfterContentChecked(): void {
  	this.cdr.detectChanges();
  }

	menuOpened(event: any): void {
		this.width = event.currentTarget.
    getBoundingClientRect().width.toPrecision(3) - 3;
		const items: any = document.querySelector("button-menu-item")
		items?.parentNode?.setAttribute('style', `min-width:${this.width}px`);
	}

}
