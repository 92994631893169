import { Subject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.css', '../../domain/primecap/components/modal-primecap/modal-primecap.component.css']
})
export class ModalConfirmacaoComponent implements OnInit {

  @Input() titulo: string;
  @Input() mensagem: string;
  @Input() cancelarAcao: string;
  @Input() confirmarAcao: string;

  confirmar: Subject<boolean>;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacaoComponent>,
  ) {
    this.confirmar = new Subject();
    if(typeof this.dialogRef.addPanelClass === 'function') {
      this.dialogRef.addPanelClass('engenharia-modal-primecap');
    }
    if(this.dialogRef._containerInstance && this.dialogRef._containerInstance._config) {
      this.dialogRef._containerInstance._config.autoFocus = false;
    }
  }

  ngOnInit(): void {
  }

  fecharModal() {
    this.confirmar.next(false);
  }

  confirmarModal() {
    this.confirmar.next(true);
  }

}
