import { Component } from '@angular/core';

@Component({
  selector: 'erro-500',
  templateUrl: '500.component.html',
  styleUrls: ['error.component.css']
})
export class P500Component {

  constructor() { }

}
