import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// componentes de template
import { P404Component } from './domain/primecap/presentation/layouts/error/404.component';
import { P500Component } from './domain/primecap/presentation/layouts/error/500.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./domain/domain.module').then(m => m.DomainModule)
  },
  {
    path: '500',
    component: P500Component
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
