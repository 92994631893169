import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ConfiguracoesCoreService } from 'src/app/domain/primecap/core/configuracoes-core.service';
import { Configuracoes } from '../../../model/configuracoes';

@Component({
  selector: 'footer-primecap',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  configuracoes: Configuracoes;

  constructor(private configuracoesCoreService: ConfiguracoesCoreService) { }

  ngOnInit(): void {
    this.configuracoesCoreService.getConfiguracoes().pipe(take(1)).subscribe(
      res => {
        this.configuracoes = res.dados[0];
        this.configuracoesCoreService.setValorConfiguracoes(res.dados[0]);
      }
    );
  }

}
