import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageHandlerService } from '../shared/message-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private messageHandler: MessageHandlerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status != 401 && !request.url.includes('/municipios/ibge/undefined')) {
          return this.messageHandler.handleError(error);
        }
        return throwError(error);
      })
    )
  }


}
