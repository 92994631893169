import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioAutenticadoGuard  {
  
  constructor(
    private router: Router,
    private tokenService: TokenService
  ){}

  canActivate(){
    let token = this.tokenService.carregarToken();
    if (token != null) {
      return true;
    } else {
      this.router.parseUrl('/login');
      return false;
    }
  }
  
}
