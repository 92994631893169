import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioLogado } from '../domain/primecap/model/usuario-logado';
import { MasterService } from '../domain/primecap/presentation/layouts/master/master.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private router: Router,
    private masterService: MasterService  
  ) { }

  encerrarSessao(): void {
    sessionStorage.clear();
    this.masterService.setMenu();
    this.router.navigate([`/login`]);
  }

  public armazenarToken(token: string): void {
    sessionStorage.removeItem('accessToken');
    sessionStorage.setItem('accessToken', token);
  }

  public carregarToken(): string | null {
    return sessionStorage.getItem('accessToken');
  }

  public armazenarRefreshToken(token: string): void {
    sessionStorage.removeItem('refreshToken');
    sessionStorage.setItem('refreshToken', token);
  }

  public carregarRefreshToken(): string | null {
    return sessionStorage.getItem('refreshToken');
  }

  public armazenarUsuarioLogado(usuario: UsuarioLogado): void {
    sessionStorage.removeItem('user');
    sessionStorage.setItem('user', JSON.stringify(usuario));
  }

  public carregarUsuarioLogado(): UsuarioLogado {
    let usuarioLogado: UsuarioLogado;
    const usuario = sessionStorage.getItem('user');
    if (usuario) {
      usuarioLogado = JSON.parse(usuario);
    }

    return usuarioLogado;
  }

  armazenarUuid(uuid: string): void {
    sessionStorage.removeItem('uuid');
    sessionStorage.setItem('uuid', uuid);
  }

  removerUuid(): void {
    sessionStorage.removeItem('uuid');
  }

  carregarUuid(): string {
    return sessionStorage.getItem('uuid');
  }
}
