import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectPrimecapComponent } from './select-primecap.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsPrimecapModule } from '../icons-primecap/icons-primecap.module';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    SelectPrimecapComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
		ReactiveFormsModule,
    IconsPrimecapModule,
    MatInputModule,
    MatIconModule
  ],
  exports: [
    SelectPrimecapComponent
  ]
})
export class SelectPrimecapModule { }
