import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private carregandoReq = new BehaviorSubject<boolean>(false);
  carregandoReq$ = this.carregandoReq.asObservable();

  constructor() { }

  setCarregando(carregando: boolean) {
    this.carregandoReq.next(carregando);
  }

  getCarregando() {
    return this.carregandoReq.getValue();
  }
}
