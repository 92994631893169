import { NgxCurrencyInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: 0,
  max: 99999999999999999,
  inputMode: NgxCurrencyInputMode.Financial
};
