import { PipesModule } from './../../../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPrimecapComponent } from './card-primecap.component';
import {MatCardModule} from '@angular/material/card';
import { ProgressbarPrimecapModule } from '../progressbar-primecap/progressbar-primecap.module';
import { SetorPrimecapModule } from '../setor-primecap/setor-primecap.module';
import { IconsPrimecapModule } from '../icons-primecap/icons-primecap.module';
import { CardListaInvestimentoComponent } from './card-lista-investimento/card-lista-investimento.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    CardPrimecapComponent,
    CardListaInvestimentoComponent
  ],
  imports: [
    CommonModule,
    ProgressbarPrimecapModule,
    MatCardModule,
    MatTableModule,
    SetorPrimecapModule,
    IconsPrimecapModule,
    PipesModule
  ],
  exports:[
    CardPrimecapComponent,

  ]
})
export class CardPrimecapModule { }
