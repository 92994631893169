import { TipoRespostaHttp } from '../domain/primecap/model/enum/tipo-error.enum';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrimecapError } from '../domain/primecap/model/primecap-error';
import { RespostaHttp } from '../domain/primecap/model/resposta-http';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  exibirMensagem(mensagem: string, isError: boolean): void {
    this.snackBar.open(mensagem, 'x', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: isError ? ['snackbar-danger'] : ['snackbar-success']
    });
  };

  exibirMensagemErro(erro: PrimecapError): void {
    let msg: string = 'Ocorreu um problema, tente novamente mais tarde!';
    if (erro?.mensagem && erro?.mensagem != "") {
      msg = erro.mensagem;

      if (erro.erros?.length > 0) {
        erro.erros[0]?.validacoes?.forEach(er => msg = msg.concat(` ${er.descricao}`));
      }
    }
    this.abrirSnackBar(msg, erro?.tipo);
  };

  exibirMensagemSucesso(resp: RespostaHttp<any>): void {
    let msg: string = 'Operação realizada com sucesso!';
    if (resp.mensagem && resp.mensagem != '') {
      msg = resp.mensagem;
    }
    this.abrirSnackBar(msg, resp.tipo);
  }

  private abrirSnackBar(msg: string, tipoResposta: TipoRespostaHttp) {
    this.snackBar.open(msg, 'x', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: this.definirPanelClass(tipoResposta)
    });
  }

  private definirPanelClass(tipoResposta: TipoRespostaHttp): string {
    let panelClass: string = '';
    switch (tipoResposta) {
      case TipoRespostaHttp.INFO:
        panelClass = 'snackbar-success';
        break;
      case TipoRespostaHttp.WARNING:
        panelClass = 'snackbar-warning';
        break;
      case TipoRespostaHttp.ERROR:
        panelClass = 'snackbar-danger';
        break;
      default:
        panelClass = 'snackbar-warning';
        break;
    }
    return panelClass;
  }

}
