import { IconsPrimecapModule } from './../icons-primecap/icons-primecap.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressbarPrimecapComponent } from './progressbar-primecap.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    ProgressbarPrimecapComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    IconsPrimecapModule
  ],
  exports: [
    ProgressbarPrimecapComponent
  ]
})
export class ProgressbarPrimecapModule { }
