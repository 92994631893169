import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IconsPrimecapService } from './icons-primecap.service';
import { IconsClass } from './model/icons-class';


@Component({
  selector: 'icons-primecap',
  templateUrl: './icons-primecap.component.html',
  styleUrls: ['./icons-primecap.component.css']
})
export class IconsPrimecapComponent implements OnInit {

  @Input()
  @HostBinding('class')
  size: 'small' | 'large' | 'default' = 'large';
  public svgContent: Observable<SafeHtml> | undefined;
  @Input() iconsClass?: IconsClass;
  @Output() clickButton = new EventEmitter<MouseEvent>();
  constructor(
    private sanitizer: DomSanitizer,
    private iconService: IconsPrimecapService
  ) { }

  ngOnInit(): void { }

  @Input()
  set name(value: string) {
    this.setSvgContent(`/assets/icons/${value}.svg`);
  }

  private setSvgContent(src: string): void {
    this.svgContent = this.iconService
      .getSvgContent(src)
      .pipe(map( 
        content => 
          this.sanitizer.bypassSecurityTrustHtml(content)
      ));
  }
  primecapIconClick(): void {
    this.clickButton.emit();
  }


}
