import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipePrimecapComponent } from './equipe-primecap.component';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    EquipePrimecapComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    EquipePrimecapComponent
  ]
})
export class EquipePrimecapModule { }
