<mat-sidenav-container class="primecap-container">
  <mat-sidenav mode="side" opened="true" *ngIf="isAutentication">
    <div class="sidenav" [ngClass]="{'sidenav-collapsed': collapsed}">
      <div class="logo-container" (click)="index()">
        <img class="logo" *ngIf="!collapsed" src="assets/images/logo-branca.png" alt="logo primecap">
        <img class="logo-text" *ngIf="collapsed" src="assets/images/logo-primecap-branca.png" alt="logo primecap">
      </div>
      <hr class="divisor">
      <div *ngFor="let item of menuSectionItems; let i = index">
        <div class="sidenav-section-title" *ngIf="item?.sectionName">
          {{ item?.sectionName }}
          <hr class="divisor">
        </div>

        <ul class="sidenav-nav">
          <li class="sidenav-nav-item" *ngFor="let item of menuSectionItems[i].menuItens">
            <a *ngIf="item.subItens && item.subItens.length > 0" (click)="exibirSubItens(item, i)" class="sidenav-nav-link">
              <icons-primecap class="sidenav-nav-icon" name="{{item.icon}}"></icons-primecap>
              <span class="sidenav-link-text" *ngIf="collapsed">{{item.label}}</span>
            </a>

            <a *ngIf="!item.subItens || (item.subItens && item.subItens.length === 0)" routerLink="{{item.routerLink}}" class="sidenav-nav-link">
              <icons-primecap class="sidenav-nav-icon" name="{{item.icon}}"></icons-primecap>
              <span class="sidenav-link-text" *ngIf="collapsed">{{item.label}}</span>
            </a>

            <ul *ngIf="collapsed && item.subItens && item.subItens.length > 0">
              <li class="sidenav-nav-item" *ngFor="let subItem of item.subItens">
                <a *ngIf="item.expanded" routerLink="{{subItem.routerLink}}" class="sidenav-nav-link">
                  <icons-primecap *ngIf="subItem.icon" class="sidenav-nav-subIcon" name="{{subItem.icon}}"></icons-primecap>
                  <span class="sidenav-link-text">{{subItem.label}}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="info-container" *ngIf="collapsed && usuario?.tipoUsuario == tipoUsuarioEnum.INVESTIDOR">
        <h3>Meu Assessor Primecap</h3>
        <p class="info-paragrafo">
          <icons-primecap name="user" [size]="'small'"></icons-primecap>
          <span class="info-nome-assessor">
            {{ assessor?.nome }}
          </span>
        </p>
        <p class="info-paragrafo">
          <icons-primecap name="whatsapp" [size]="'small'"></icons-primecap>
          <span class="info-texto">
            {{ assessor?.telefone | mask: '(00) 00000-0000'}}
          </span>
        </p>
        <p class="info-paragrafo">
          <icons-primecap name="email" [size]="'small'"></icons-primecap>
          <span class="info-texto">
            {{ assessor?.email }}
          </span>
        </p>
      </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content
  [style]="styleInit"
  [ngStyle]="{'margin-left.rem': contentMargin}"
  >
    <mat-toolbar class="toolbar-{{toolbarClass}}" *ngIf="toolbarClass != 'production'">
      <p class="m-4 text-uppercase">{{ toolbarClass }}</p>
    </mat-toolbar>

    <mat-toolbar *ngIf="isAutentication">
      <icons-primecap
        class="icon-menu"
        *ngIf="isAutentication"
        (click)="openCloseMenu()"
        name="menu"
      ></icons-primecap>
      <div class="group-user">
        <span>
          <p>
            {{nomeUsuario}}
          </p>
        </span>
        <button class="content-user" mat-icon-button color="primary" [matMenuTriggerFor]="menuUsuario">
          <icons-primecap
          iconsClass="container-icons"
          name="user1"
          ></icons-primecap>
        </button>
        <mat-menu class="content-user-menu-itens" #menuUsuario="matMenu" xPosition="before">
            <button mat-menu-item (click)="redirecionarUsuario()">
                Perfil
                <mat-icon class="menu-icon">person</mat-icon>
            </button>
            <button mat-menu-item (click)="logout()">
                Sair
                <mat-icon class="menu-icon">logout</mat-icon>
            </button>
        </mat-menu>
      </div>
    </mat-toolbar>

    <mat-toolbar id="toolbar-fixa" *ngIf="!isAutentication">
      <div id="toolbar-index">
          <div class="toolbar-logo" (click)="index()">
            <img  class="logo-index" src="assets/images/logo-primecap-azul.png" alt="logo primecap">
          </div>
          <div class="botoes-toobar">
            <button-primecap
              label="QUERO INVESTIR"
              buttonClass="terciario-button"
              [disabled]="false"
              (clickButton)="investir()"
            ></button-primecap>
            <button-menu
                buttonClass="terciario-button"
                label="QUERO CAPTAR"
              >
                <button-menu-item
                label="Startups"
                (clickButton)="captarStartup()"
                ></button-menu-item>
                <button-menu-item
                (clickButton)="captarRealState()"
                label="Real Estate"
                ></button-menu-item>
              </button-menu>
            <button-primecap
              label="ENTRAR"
              buttonClass="secundario-button"
              [disabled]="false"
              (clickButton)="logar()"
            ></button-primecap>
            <button-primecap
              label="CRIAR CONTA"
              buttonClass="primario-button"
              [disabled]="false"
              (clickButton)="criarConta()"
            ></button-primecap>
          </div>
      </div>
    </mat-toolbar>
    <div [ngStyle]="{'margin-top.rem': contentMarginTop}">
      <primecap-loader *ngIf="loaderService.carregandoReq$ | async"></primecap-loader>
      <ng-content *ngIf="!loaderService.getCarregando()"></ng-content>
      <footer-primecap *ngIf="isAutentication && this.usuario?.tipoUsuario == tipoUsuarioEnum.INVESTIDOR"></footer-primecap>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
