import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ItemComponent } from './item/item.component';
import { ContentComponent } from './content/content.component';
import { TabPrimecapComponent } from './tab-primecap.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    ItemComponent,
    ContentComponent,
    TabPrimecapComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
		MatTabsModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule
  ],
  exports: [
    TabPrimecapComponent,
    ItemComponent,
    ContentComponent,
    HeaderComponent
  ]
})
export class TabPrimecapModule { }
