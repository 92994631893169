<div class="m-5">
  <main class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-2 mx-auto my-auto">
          <div class="mt-5">
            <ng-container>
              <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

