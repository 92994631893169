import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'button-menu-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ButtonMenuItemComponent {

  @Input() label?: string;
  @Input() disabled = false;

  @Output() clickButton = new EventEmitter<MouseEvent>();

  constructor() {
  }

  primecapButtonMenuClick(): void {
    this.clickButton.emit();
  }
}
