import { NgModule } from '@angular/core';
import { SetorPrimecapComponent } from './setor-primecap.component';

@NgModule({
  declarations:[
    SetorPrimecapComponent
  ],
  imports: [

  ],
  exports: [SetorPrimecapComponent]
})
export class SetorPrimecapModule{}
