
<div id="button">
  <button type="button"
    class="{{buttonClass}}"
    [disabled]="disabled"
    (click)="primecapButtonClick()"
    (keydown.enter)="primecapButtonEnter()"
  >
    <icons-primecap *ngIf="icon" name="{{nomeIcon}}"></icons-primecap>
    <span class="label-primecap">{{label}}</span>
  </button>
</div>
