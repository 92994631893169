import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { classButton } from './model/class-button';

@Component({
  selector: 'button-primecap',
  templateUrl: './button-primecap.component.html',
  styleUrls: ['./button-primecap.component.css']
})
export class ButtonPrimecapComponent implements OnInit {

  @Input() label?: string;
  @Input() buttonClass?: classButton;
  @Input() disabled: boolean = false;
  @Input() icon?: boolean = false;
  @Input() nomeIcon?: string;

  @Output() clickButton = new EventEmitter<MouseEvent>();
  @Output() enterButton = new EventEmitter<KeyboardEvent>();

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.enterButton.emit(event);
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
  	this.cdr.detectChanges();
  }

  ngAfterContentInit(): void {
  	if (!this.buttonClass) {
  		this.buttonClass = 'primario-button';
  	}
  }

  primecapButtonClick(): void {
    this.clickButton.emit();
  }

  primecapButtonEnter(): void {
    this.enterButton.emit();
  }
}
