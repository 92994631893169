import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitazer: DomSanitizer) {}

  transform(hmtlElement: string): SafeHtml {
    return this.sanitazer.bypassSecurityTrustHtml(hmtlElement);
  }

}
