import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnackBarService } from '../shared/snack-bar.service';

@Injectable()
export class ViacepInterceptor implements HttpInterceptor {

  constructor(
    private snackBarService: SnackBarService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request;

    if (request.url.includes('//viacep')) {
      req = request.clone({
        headers: request.headers
        .delete('Authorization')
        .delete('Cache-Control')
        .delete('Pragma')
      });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && JSON.stringify(event.body) == '{"erro":true}') {
          this.snackBarService.exibirMensagem('Houve um erro ao buscar o endereço com o cep informado.', true);
        }
        return event
      })
    );
  }
}
