import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmacaoComponent } from './modal-confirmacao.component';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmacaoService {

  constructor(
    private dialog: MatDialog
  ) { }

  mostrarConfirmacao(titulo?: string, mensagem?: string, textoConfirmar?: string, textoCancelar?: string) {
    const modal = this.dialog.open(ModalConfirmacaoComponent)

    if (titulo) {
      modal.componentInstance.titulo = titulo;
    } else {
      modal.componentInstance.titulo = 'Confirmar';
    }

    if (mensagem) {
      modal.componentInstance.mensagem = mensagem;
    } else {
      modal.componentInstance.mensagem = 'Tem certeza que deseja excutar esta ação?';
    }

    if (textoConfirmar) {
      modal.componentInstance.confirmarAcao = textoConfirmar;
    } else {
      modal.componentInstance.confirmarAcao = 'Sim';
    }

    if (textoCancelar) {
      modal.componentInstance.cancelarAcao = textoCancelar;
    } else {
      modal.componentInstance.cancelarAcao = 'Não';
    }
    return (<ModalConfirmacaoComponent>modal.componentInstance).confirmar;
  }
}
