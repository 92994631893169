import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDetalhesComponent } from './card-detalhes.component';
import { ProgressbarPrimecapModule } from '../progressbar-primecap/progressbar-primecap.module';
import { MatCardModule } from '@angular/material/card';
import { SetorPrimecapModule } from '../setor-primecap/setor-primecap.module';
import { IconsPrimecapModule } from '../icons-primecap/icons-primecap.module';
import { ButtonPrimecapModule } from '../button-primecap/button-primecap.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    CardDetalhesComponent
  ],
  imports: [
    CommonModule,
    ProgressbarPrimecapModule,
    MatCardModule,
    SetorPrimecapModule,
    IconsPrimecapModule,
    ButtonPrimecapModule,
    PipesModule
  ],
  exports: [
    CardDetalhesComponent
  ]
})
export class CardDetalhesModule { }
