import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TabelaCrudModule } from './tabela-crud/tabela-crud.module';
import { PipesModule } from '../pipes/pipes.module';
import { CampoControlErroModule } from './campo-control-erro/campo-control-erro.module';
import { ModalConfirmacaoComponent } from './modal-confirmacao/modal-confirmacao.component';
import { ComponentsModule } from '../domain/primecap/components/components.module';
import { ModalExcluirComponent } from './modal-excluir/modal-excluir.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxCurrencyDirective } from "ngx-currency";


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        MatDialogModule,
        MatTableModule,
        MatIconModule,
        MatSnackBarModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxCurrencyDirective,
        TabelaCrudModule,
        PipesModule,
        ComponentsModule,
        CampoControlErroModule,
    ],
    declarations: [
        ModalConfirmacaoComponent,
        ModalExcluirComponent,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        TabelaCrudModule,
        CampoControlErroModule,
        ComponentsModule,
        MatDialogModule,
        MatTableModule,
        MatIconModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxCurrencyDirective,
        ModalConfirmacaoComponent,
        ModalExcluirComponent,
    ],
    providers: [
      provideEnvironmentNgxMask()
    ]
})
export class SharedModule { }
