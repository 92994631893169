<div class="container footer">
  <div class="up-footer">
    <img src="assets/images/selo-empreendedorismo-brasileiro.png" alt="selo empresa brasileira">
    <div class="coluna-1">
      <ul>
        <p class="titulo-coluna">Legal</p>
        <li><a [href]="configuracoes?.politicaPrivacidade" target="_blank">Política de Privacidade</a></li>
        <li><a [href]="configuracoes?.regrasGerais" target="_blank">Regras Gerais</a></li>
        <li><a [href]="configuracoes?.termosServico" target="_blank">Termos de Serviço</a></li>
        <li><a [href]="configuracoes?.avisoRiscos" target="_blank">Aviso de Risco</a></li>
        <li><a [href]="configuracoes?.impostoRenda" target="_blank">Imposto de Renda</a></li>
      </ul>
    </div>
    <div class="coluna-2">
      <ul>
        <p class="titulo-coluna">Primecap</p>
        <li><a [href]="configuracoes?.materialDidatico" target="_blank">Material Didático</a></li>
        <li><a [href]="configuracoes?.ofertasConsolidadas" target="_blank">Ofertas Consolidadas</a></li>
      </ul>
    </div>
    <div class="coluna-3">
      <ul>
        <p class="titulo-coluna">Social</p>
        <li>Whatsapp: +55 19 99937-8647</li>
        <li>E-mail: <a href="mailto:contato@primecap.com.br">contato@primecap.com.br</a></li>
      </ul>
      <div class="icon-redes-sociais-container">
        <a [href]="configuracoes?.linkFacebook ? configuracoes?.linkFacebook : '#'" target="_blank">
          <img src="assets/images/facebook.png" alt="Ícone do Facebook">
        </a>
        <a [href]="configuracoes?.linkInstagram ? configuracoes?.linkInstagram : '#'" target="_blank">
          <img src="assets/images/instagram.png" alt="Ícone do Instagram">
        </a>
        <a [href]="configuracoes?.linkLinkedin ? configuracoes?.linkLinkedin : '#'" target="_blank">
          <img src="assets/images/linkedin.png" alt="Ícone do Linkedin">
        </a>
      </div>
    </div>
  </div>
  <hr class="divisor">
  <div class="down-footer">
    <div class="logo-cvm">
      <img src="assets/images/logo-cvm-preta.png" alt="Logo da CVM">
    </div>
    <p class="aviso">
      Aviso importate, leia com atenção: As sociedades empresárias de pequeno porte e as ofertas apresentadas nessa plataforma estão automaticamente dispensadas de registro pela Comissão de Valores Mobiliários - CVM. A CVM não analisa previamente as ofertas. As ofertas realizadas não implicam por parte da CVM a garantia da veracidade das informações prestadas, de adequação à legislação vigente ou julgamento sobre a qualidade da sociedade empresária de pequeno porte. Antes de aceitar uma oferta, leia com atenção as informações essenciais da oferta, em especial a seção de alertas sobre riscos. A Primecap Plataforma de Investimentos Coletivos Ltda (CNPJ (MF) sob nº 35.086.794/0001-32) é uma plataforma de investimento autorizada pela Comissão de Valores Mobiliários (CVM).
    </p>
  </div>
  <div class="copy">
    <p>Copyright 2020 | Primecap</p>
  </div>
</div>
