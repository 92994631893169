<div class="modal-container">
	<div mat-dialog-title>
    <h1>{{ titulo }}</h1>
		<button mat-icon-button class="close-icon" [mat-dialog-close]="false">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="body">
		<div mat-dialog-content [innerHTML]="mensagem | safeHtml">
			<!-- {{ mensagem }} -->
		</div>
		<div mat-dialog-actions>
      <button-primecap buttonClass="terciario-button-g" label="{{ cancelarAcao }}" [mat-dialog-close]="true" (clickButton)="fecharModal()"></button-primecap>
      <button-primecap buttonClass="primario-button-g" label="{{ confirmarAcao }}" [mat-dialog-close]="false" (clickButton)="confirmarModal()"></button-primecap>
		</div>
	</div>
</div>
