import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardGeometricoComponent } from './card-geometrico.component';


@NgModule({
  declarations: [
    CardGeometricoComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CardGeometricoComponent
  ]
})
export class CardGeometricoModule { }
