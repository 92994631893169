import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Configuracoes } from '../model/configuracoes';
import { RespostaHttp } from '../model/resposta-http';
import { ListaPaginada } from '../model/lista-paginada';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracoesCoreService {

  private atualizaConfiguracoes = new BehaviorSubject<any>({});
  atualizaConfiguracoes$ = this.atualizaConfiguracoes.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  postConfiguracoes(configuracoes: Configuracoes): Observable<RespostaHttp<Configuracoes>> {
    return this.http.post<RespostaHttp<Configuracoes>>(`${environment.apiUrl}/cadastros/configuracoes`, configuracoes).pipe(take(1));
  }

  getConfiguracoes(): Observable<RespostaHttp<Configuracoes>> {
    return this.http.get<RespostaHttp<Configuracoes>>(`${environment.apiUrl}/cadastros/configuracoes`).pipe(take(1));
  }

  putConfiguracoes(configuracoes: Configuracoes): Observable<RespostaHttp<Configuracoes>> {
    return this.http.put<RespostaHttp<Configuracoes>>(`${environment.apiUrl}/cadastros/configuracoes`, configuracoes).pipe(take(1));
  }

  setValorConfiguracoes(configuracoes: Configuracoes) {
    this.atualizaConfiguracoes.next(configuracoes);
  }
}
