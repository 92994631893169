import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PrimecapError } from '../domain/primecap/model/primecap-error';
import { RespostaHttp } from '../domain/primecap/model/resposta-http';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class MessageHandlerService {

  constructor(
    private snackBarService: SnackBarService
  ) { }

  handleError(httpError: HttpErrorResponse): Observable<never> {
    const primecapError: PrimecapError = httpError.error;

    if (httpError instanceof HttpErrorResponse) {
      switch (httpError.status) {
        case 0:
        case 503:
          this.snackBarService.exibirMensagem('Servidor indisponível!', true);
          break;
        case 401:
          this.snackBarService.exibirMensagem('Sessão de usuário expirada. Efetue login novamente!', true);
          break;
        case 403:
          this.snackBarService.exibirMensagem('Operação não permitida!', true);
          break;
        default:
          this.snackBarService.exibirMensagemErro(primecapError);
          break;
      }
    } else {
      this.snackBarService.exibirMensagem('Ocorreu um problema. Tente novamente mais tarde!', true);
    }

    return EMPTY;
  }

  handleResponse(httpResponse: RespostaHttp<any>): Observable<never> {
    switch (httpResponse?.status) {
      case 200:
      case 201:
        this.snackBarService.exibirMensagemSucesso(httpResponse);
        break;
      case 204:
        this.snackBarService.exibirMensagem('Registro removido!', false);
        break;
      default:
        this.snackBarService.exibirMensagem('Operação realizada com sucesso!', false);
        break;
    }

    return EMPTY;
  }

  handleMessage(msg: string, isError: boolean): void {
    return this.snackBarService.exibirMensagem(msg, isError);
  }
}
