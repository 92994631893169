import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';

// Locale para pipe de data
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

// constantes
import { interceptorProviders } from './interceptors/interceptors';
import { guardsProviders } from './guards/guards';
import { SharedModule } from './shared/shared.module';
import { NgxCurrencyDirective, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { customCurrencyMaskConfig } from './constantes/constante-ngx-currency';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxCurrencyDirective
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    interceptorProviders,
    guardsProviders,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    provideEnvironmentNgxCurrency(customCurrencyMaskConfig),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
