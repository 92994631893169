import { Investidor } from './../model/investidor';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { ListaPaginada } from '../model/lista-paginada';
import { ParametrosPaginacao } from '../model/interface/parametros-paginacao';
import { AssessorDoInvestidor } from '../model/assessor-do-investidor';
import { RespostaHttp } from '../model/resposta-http';
import { InvestidorPendente } from '../model/investidor-pendente';

@Injectable({
  providedIn: 'root'
})
export class InvestidorCoreService {

  constructor(
    private http: HttpClient
  ) { }

  postInvestidor(investidor: Investidor): Observable<RespostaHttp<Investidor>> {
    return this.http.post<RespostaHttp<Investidor>>(`${environment.apiUrl}/investidores`, investidor).pipe(take(1));
  }

  getInvestidores(paginacao: ParametrosPaginacao): Observable<RespostaHttp<ListaPaginada<Investidor>>> {
    let url: string;
    if (paginacao.tamanhoPagina != null && paginacao.indicePagina != null) {
      url = `${environment.apiUrl}/investidores?size=${paginacao.tamanhoPagina}&page=${paginacao.indicePagina}`;
    } else if (paginacao.tamanhoPagina != null) {
      url = `${environment.apiUrl}/investidores?size=${paginacao.tamanhoPagina}`;
    }
    return this.http.get<RespostaHttp<ListaPaginada<Investidor>>>(url).pipe(take(1));
  }

  getInvestidoresPendentes(paginacao: ParametrosPaginacao): Observable<RespostaHttp<ListaPaginada<InvestidorPendente>>> {
    let url: string;
    if (paginacao.tamanhoPagina != null && paginacao.indicePagina != null) {
      url = `${environment.apiUrl}/usuarios/pendentes?size=${paginacao.tamanhoPagina}&page=${paginacao.indicePagina}`;
    } else if (paginacao.tamanhoPagina != null) {
      url = `${environment.apiUrl}/usuarios/pendentes?size=${paginacao.tamanhoPagina}`;
    }
    return this.http.get<RespostaHttp<ListaPaginada<InvestidorPendente>>>(url).pipe(take(1));
  }

  getInvestidorPorUuid(investidorUuid: string): Observable<RespostaHttp<Investidor>> {
    return this.http.get<RespostaHttp<Investidor>>(`${environment.apiUrl}/investidores/${investidorUuid}`).pipe(take(1));
  }

  getInvestidorPorNome(investidorNome: string, paginacao: ParametrosPaginacao): Observable<RespostaHttp<ListaPaginada<Investidor>>> {
    let url: string;
    if (investidorNome == '') {
      url =`${environment.apiUrl}/investidores?size=${paginacao.tamanhoPagina}&page=${paginacao.indicePagina}`;
    } else {
      url = `${environment.apiUrl}/investidores/filtros?nome=${investidorNome}&size=${paginacao.tamanhoPagina}&page=${paginacao.indicePagina}&sort=nome`;
    }
    return this.http.get<RespostaHttp<ListaPaginada<Investidor>>>(url).pipe(take(1));
  }

  getAssessorDoInvestidor(investidorUuid: string): Observable<RespostaHttp<AssessorDoInvestidor>> {
    return this.http.get<RespostaHttp<AssessorDoInvestidor>>(`${environment.apiUrl}/investidores/${investidorUuid}/dashboard/meu-assessor`).pipe(take(1));
  }

  putInvestidor(investidor: Investidor): Observable<RespostaHttp<Investidor>> {
    return this.http.put<RespostaHttp<Investidor>>(`${environment.apiUrl}/investidores/${investidor.uuid}`, investidor).pipe(take(1));
  }

  deleteInvestidor(investidorUuid: string): Observable<RespostaHttp<Investidor>> {
    return this.http.delete<RespostaHttp<Investidor>>(`${environment.apiUrl}/investidores/${investidorUuid}`).pipe(take(1));
  }
}
