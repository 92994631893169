import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';;
import { environment } from '../../../../environments/environment';
import { ApiUrlEnum } from '../model/enum/api-url.enum';
import { UsuarioAdministrativo } from '../model/usuario-administrativo';
import { UsuarioRegistro } from '../model/usuario-registro';
import { UsuarioLogin } from '../model/usuario-login';
import { RespostaHttp } from '../model/resposta-http';
import { UsuarioBuscado } from '../model/usuario-buscado';
import { RefreshToken } from '../model/refresh-token';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoCoreService {

  constructor(
    private http: HttpClient,
  )
  { }

  login(email: string, senha: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/autenticacao/login`, {
      email,
      senha
    }).pipe(take(1));
  }

  registrarUsuario(usuarioRegistro: UsuarioRegistro): Observable<RespostaHttp<UsuarioRegistro>>{
    return this.http.post<RespostaHttp<UsuarioRegistro>>(`${environment.apiUrl}/usuarios`, usuarioRegistro).pipe(take(1));
  }

  registrarUsuarioAdministrativo(usuarioRegistro: UsuarioAdministrativo): Observable<RespostaHttp<UsuarioAdministrativo>>{
    return this.http.post<RespostaHttp<UsuarioAdministrativo>>(`${environment.apiUrl}/usuarios/administrativos`, usuarioRegistro).pipe(take(1));
  }

  atualizarUsuarioAdministrativo(usuarioRegistro: UsuarioAdministrativo): Observable<RespostaHttp<UsuarioAdministrativo>>{
    return this.http.put<RespostaHttp<UsuarioAdministrativo>>(`${environment.apiUrl}/usuarios/administrativos/${usuarioRegistro.uuid}`, usuarioRegistro).pipe(take(1));
  }

  gerarNovoToken(token: string): Observable<RespostaHttp<RefreshToken>>{
    return this.http.post<RespostaHttp<RefreshToken>>(`${environment.apiUrl}/autenticacao/refresh-token`, {
      refreshToken: token
    }).pipe(take(1));
  }

  buscarUsuario(urlUsuario: ApiUrlEnum, usuarioUuid: string): Observable<RespostaHttp<UsuarioBuscado>> {
    return this.http.get<RespostaHttp<UsuarioBuscado>>(`${environment.apiUrl}/${urlUsuario}/usuario/uuid/${usuarioUuid}`).pipe(take(1));
  }

  redefinirSenha(usuarioUuid: string, senhaAtual: string, senhaNova: string): Observable<RespostaHttp<any>> {
    return this.http.put<RespostaHttp<any>>(`${environment.apiUrl}/usuarios/${usuarioUuid}/troca-senha`, {senhaAtual, senhaNova}).pipe(take(1));
  }

  enviarTokenRedefinicaoSenha(email: string): Observable<RespostaHttp<any>> {
    return this.http.post<RespostaHttp<any>>(`${environment.apiUrl}/usuarios/enviar-token`, email).pipe(take(1));
  }

  resetarSenha(token: string, senha: string, confirmacaoSenha: string): Observable<RespostaHttp<any>> {
    return this.http.post<RespostaHttp<any>>(`${environment.apiUrl}/usuarios/resetar-senha?token=${token}`, {senha, confirmacaoSenha}).pipe(take(1));
  }
}
